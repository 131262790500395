<template>
    <div class="outerBox">
		<div class="tableHead">
			<div class="headText" :style="{width: columns.length?columns[0].width+'%':'0', marginLeft: '4.21%'}">{{columns.length?columns[0].title:''}}</div>
			<div class="headText" :style="{width: columns.length?columns[1].width+'%':'0'}">{{columns.length?columns[1].title:''}}</div>
			<div class="headText" :style="{width: columns.length?columns[2].width+'%':'0'}">{{columns.length?columns[2].title:''}}</div>
			<div class="headText" :style="{width: columns.length?columns[3].width+'%':'0'}">{{columns.length?columns[3].title:''}}</div>
		</div>
		<scrollview class="tableRowBox" speed="15" :sKey="data">
			<div class="tableRow" v-for="(item, index) in data" :key="index">
				<div class="tableText" :style="{width: columns.length?columns[0].width+'%':'0', marginLeft: '4.21%'}">{{item[columns[0].key]}}</div>
				<div class="tableText" :style="{width: columns.length?columns[1].width+'%':'0'}">{{item[columns[1].key]}}</div>
				<div class="tableText" :style="{width: columns.length?columns[2].width+'%':'0'}">{{item[columns[2].key]}}</div>
				<div class="specialText" :style="{width: columns.length?columns[3].width+'%':'0', color: item.color}">
					<div class="statusPoint" :style="{backgroundColor: item.color}"></div>
					{{item[columns[3].key]}}
				</div>
			</div>
		</scrollview>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	width: 100%;

	.tableHead {
		width: 100%;
		height: 2.96vh;
		background: rgba(243,184,5,0.2);
		display: flex;
		align-items: center;

		.headText {
			height: 68.75%;
			font-size: 1.3vh;
			font-family: 'pingfangSc', sans-serif;
			color: rgba(255,255,255,0.8);
			line-height: 2.04vh;
			display: flex;
			align-items: center;
			overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
		}
	}
	.tableRowBox {
		width: 100%;
		height: 23.34vh;
		.tableRow {
			width: 100%;
			height: 2.96vh;
			border-bottom: #ffd03b 1px solid;
			display: flex;
			align-items: center;

			.tableText {
				height: 68.75%;
				font-size: 1.3vh;
				font-family: 'pingfangSc', sans-serif;
				color: #fff;
				line-height: 2.04vh;
				display: flex;
				align-items: center;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.specialText {
				height: 68.75%;
				font-size: 1.3vh;
				font-family: 'pingfangSc', sans-serif;
				line-height: 2.04vh;
				display: flex;
				align-items: center;

				.statusPoint {
					height: 27.27%;
					aspect-ratio: 1/1;
					border-radius: 50%;
					margin-right: 5.89%;
				}
			}
		}
	}
}
</style>

<script>
const scrollview = () => import('@/views/components/scrollview.vue');

export default {
	props:{
		columns:{
			type: Array
		},
		data:{
			type: Array
		}
	},
	components:{
		scrollview
	},
	data(){
		return {

		}
	},
	mounted(){
		return false;
	}
}
</script>

